import { store, SaveServers } from '../../../interfaces';

const initalState: store.servers = {
  servers: [],
};

const servers = (state = initalState, action: any) => {
  switch (action.type) {
    case 'SAVE_SERVERS': {
      const { payload } = action as SaveServers;
      state.servers = payload;

      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
};

export default servers;
