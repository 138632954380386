import { store, SaveGroups } from '../../../interfaces';

const initalState: store.groups = {
  groups: [],
};

const groups = (state = initalState, action: any) => {
  switch (action.type) {
    case 'SAVE_GROUPS': {
      const { payload } = action as SaveGroups;
      state.groups = payload;

      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
};

export default groups;
