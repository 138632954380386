import { combineReducers } from 'redux';
import customizer from './customizer/';
import auth from './auth/';
import history from './history';
import navbar from './navbar/Index';
import servers from './servers/index';
import groups from './groups/index';

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  servers,
  groups,
  history,
});

export default rootReducer;
