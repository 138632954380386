import {
  store,
  SaveServerHistoryAction,
  SaveGroupHistoryAction,
  SaveExportedLinksUrl,
  SaveLiveLinksAction,
} from '../../../interfaces';

const initalState: store.history = {
  servers: {},
  exportedLinksUrl: '',
  liveLinks: {},
};

const history = (state = initalState, action: any) => {
  switch (action.type) {
    case 'SAVE_LIVE_LINKS': {
      const { payload, month, year } = action as SaveLiveLinksAction;
      const links = Object.assign({}, state.liveLinks);
      links[year] = links[year] || {};
      links[year][month] = payload;
      state.liveLinks = links;

      return {
        ...state,
      };
    }
    case 'SAVE_SERVER_HISTORY': {
      const { payload, date } = action as SaveServerHistoryAction;
      state.servers[date] = payload;

      return {
        ...state,
      };
    }
    case 'SAVE_GROUP_HISTORY': {
      const { payload, server, date } = action as SaveGroupHistoryAction;
      state.servers[date] = state.servers[date] || {};
      const s = state.servers[date].find((s) => s.name === server);
      s!.groupHistory = payload;

      return {
        ...state,
      };
    }
    case 'SAVE_EXPORTED_LINKS': {
      const { payload } = action as SaveExportedLinksUrl;

      return {
        ...state,
        exportedLinksUrl: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default history;
